import React from 'react';
import Link from 'gatsby-link';
import {
  Card,
  Icon,
} from 'semantic-ui-react';

const Home = () => (
  <Card.Group>
    <Card id="about">
      <Card.Content>
        <Card.Header>About</Card.Header>
        <Card.Meta>Who is this guy?</Card.Meta>
        <Card.Description>
          <p>
            I am a programmer in the Washington, DC metro area.  Here are links to some things I have out
            on the web:
          </p>
          <ul>
            <li>
              <span>My </span>
              <Link to="/blog">blog</Link>
            </li>
            <li>
              <span>Some open source code I have worked on is up on </span>
              <a href="http://github.com/jonathana">my Github account</a>
            </li>
            <li>
              <span>I have done a bunch of presentations for local tech meetups and conferences.  Find them at:</span>
              <ul>
                <li>
                  <span>Most of my presentations so far are on </span>
                  <a href="http://slideshare.net/async_io">Slideshare</a>
                </li>
                <li>
                  <Link to="/presentations/angular_jqm.html">
                    <span>AngularJS </span>
                    <Icon color="red" name="heart" />
                    <span> jQuery Mobile</span>
                  </Link>
                  <span> for </span>
                  <Link to="http://www.meetup.com/DC-jQuery-Users-Group/events/86113412/">dcjq[15]</Link>
                </li>
              </ul>
            </li>
            <li>
              Find me on
              <a href="http://www.linkedin.com/pub/jonathan-altman/1/817/663">LinkedIn</a>
            </li>
            <li>
              I am
              <a href="https://twitter.com/async_io">async_io on twitter</a>
            </li>
          </ul>
        </Card.Description>
      </Card.Content>
    </Card>
    <Card id="contact">
      <Card.Content>
        <Card.Header>Contact</Card.Header>
        <Card.Meta>Get in touch!</Card.Meta>
        <Card.Description>
          <p>You can:</p>
          <ul>
            <li>
              <span>Send me a message on </span>
              <a href="https://twitter.com/async_io">twitter</a>
            </li>
            <li>Email me at jonathan at this domain</li>
          </ul>
        </Card.Description>
      </Card.Content>
    </Card>
    <Card id="inthebin" href="https://recycli.st/" color="grey">
      <Card.Content>
        <Card.Header>InTheBin</Card.Header>
        <Card.Meta>Status: Inactive</Card.Meta>
        <Card.Description>
          <p>
            I founded a startup that was building a mobile app for iPhone and Android to help people make
            better recycling decisions. It is currently inactive, but I learned a lot about the economics of
            waste removal, most importantly that the model is constantly evolving, which makes building a
            revenue model for allied products like a recycling app...challenging.
          </p>
          <p>
            For more information about what it was, click anywhere on this card.
          </p>
        </Card.Description>
      </Card.Content>
    </Card>
  </Card.Group>
);

export default Home;
